import * as React from 'react';
import { navigate } from 'gatsby'
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import SingleBlog from '../../modules/blog/SingleBlog';
import Blogs from '../../modules/blog/posts'
import { Helmet } from 'react-helmet';

const isBrowser = typeof window !== "undefined"

const BlogView = ({params}) => {
  if ((!params.id || !Blogs[params.id]) && isBrowser) {
    navigate('/');
    return;
  }

  const post = Blogs[params.id];
  if (!post) {
    return '';
  }
  const data = post?.data;

  return (
    <Layout title={data?.title} metaImage={`https://zegates.com${data.image}`}>
      <SingleBlog id={params.id} />
      <Footer />
    </Layout>
  );
};

export default BlogView;
